import PerformanceComponent from "@/components/dashboard/performance/PerformanceComponent.vue";
import DischargeComponent from "@/components/dashboard/discharge/DischargeComponent.vue";
import ChargeComponent from "@/components/dashboard/charge/ChargeComponent.vue";
import IdlingDischargeComponent from "@/components/dashboard/idling-discharge/IdlingDischargeComponent.vue";
import store from "@/store/store";
import { unitParser } from '@colven/common-domain-lib/lib';
import i18n from "@/i18n";

export default {
  components: {
    PerformanceComponent,
    DischargeComponent,
    ChargeComponent,
    IdlingDischargeComponent
  },
  data: () => (
    {
      internationalization:store.getters['user/getInternationalization'].unit}),
      
  mounted(){    
    this.internationalization = store.getters['user/getInternationalization'].unit;
    
  },
  methods: {
    internationalizationConvert:function(texto){return unitParser.changeUnit(texto, unitParser.UNITS_NAMES.VOLUME, this.internationalization, (text) => {return i18n.t(text)})},
  }
};
