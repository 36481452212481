import { render, staticRenderFns } from "./PerformanceComponent.vue?vue&type=template&id=1a122bbb&scoped=true"
import script from "./PerformanceComponent.js?vue&type=script&lang=js&external"
export * from "./PerformanceComponent.js?vue&type=script&lang=js&external"
import style0 from "./PerformanceComponent.vue?vue&type=style&index=0&id=1a122bbb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a122bbb",
  null
  
)

export default component.exports