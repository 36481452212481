import { idlingDischargeService } from "@/business/idling-discharge.service";
import FilterComponent from "@/components/filter/FilterComponent.vue";
import { configurationService } from "@/business/configurationService";
import i18n from "@/i18n";
import { SnackbarStyle, REPORT_TYPES } from "@/constants/constants";
import { mapActions } from "vuex";
import { filterService } from "@/business/filterService";
import { breadcrumbService } from "@/business/breadcrumb.service";

export default {
  components: {
    FilterComponent,
  },
  props: {
      internationalization: {
          required: false
        }
  },
  data: () => ({
    title: "idlingDischarges",
    model: {
      charts: [],
      filters: filterService.getFilterModel(),
    },
    showFilters: false,
    loading: false,
    reportDetails: "Deatils",
    maxQuantityLimit: 10,
    dataForRangeFilter: null,
    orderFilterWorstBestPropertyName:null,
    chartCustomButtons: []
  }),
  async mounted() {
    this.loading = true;    
    this.orderFilterWorstBestPropertyName = this.internationalization(i18n.t("dischargeSummary.filterProperties.worstBestProperty"));
    this.dataForRangeFilter= {
      showRangeData: true,
      minLimit: 0,
      maxLimit: 1000,
      propertyName: this.internationalization(i18n.t("dischargeSummary.filterProperties.idlingRangeProperty")),
    };
    try {
      const configuration = await configurationService.get(
        REPORT_TYPES.DASHBOARD_IDLING_DISCHARGE
      );
      this.model.filters =
        configuration && configuration.data && configuration.data.filters
          ? configuration.data.filters
          : filterService.getFilterModel();
      let charts = await idlingDischargeService.getIdlingDashboard(this.model.filters);
      this.model.charts.cleanAndUpdate(charts);
      this.chartCustomButtons.push({ id: 'showSummaryIdling', name: 'showReport', icon: 'mdi-file-link', function: () => { this.onClickChart(); } });
      this.reportDetails = breadcrumbService.getFilterDetailsHTMLExport(this.model.filters, "IDLING");
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions({
      showSnackbar: "snackbar/showSnackbar",
      closeSnackbar: "snackbar/closeSnackbar",
    }),
    openFilter() {
      this.showFilters = true;
    },
    closeFilter() {
      this.showFilters = false;
    },
    onClickChart() {
      this.$router.push({
        name: "idlingDischargeSummary",
        params: this.model.filters,
      });
    },
    async saveFilterChanges() {
      configurationService
        .save(REPORT_TYPES.DASHBOARD_IDLING_DISCHARGE, {
          filters: this.model.filters,
        })
        .then(() => {
          this.showSnackbar({
            visible: true,
            text: i18n.t("user.configuration.saveSuccess"),
            timeout: 10000,
            style: SnackbarStyle.SUCCESS,
          });
        })
        .catch(() => {
          this.showSnackbar({
            visible: true,
            text: i18n.t("user.configuration.saveError"),
            timeout: 10000,
            style: SnackbarStyle.ERROR,
          });
        });
      let charts = await idlingDischargeService.getIdlingDashboard(this.model.filters);
      this.model.charts.cleanAndUpdate(charts);
      this.reportDetails = breadcrumbService.getFilterDetailsHTMLExport(this.model.filters, "IDLING");
      this.closeFilter();
    },
  },
};
