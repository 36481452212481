import dischargeApi from "@/api/dischargeApi";
import thingApi from "@/api/thing.api.js";
import { configurationService } from "@/business/configurationService";
import { reportsColors } from "@/constants/constants";
import { DISCHARGE_CHAT_JSON_TEMPLATE } from "@/constants/discharge.constant";
import i18n from "@/i18n";
import store from "@/store/store";
import { processFilters, parseTimestamp } from "@/tools/functions";
import { ReportConversionUtil, TimeRanges, unitParser } from '@colven/common-domain-lib/lib';
import * as deepcopy from "deepcopy";
import { DISCHARGE_CHART_DATASET_TEMPLATE, DISCHARGE_MAP_JSON_TEMPLATE, DISCHARGE_MAP_POINT_JSON_TEMPALTE, DISCHARGE_MAP_REFERENCE_TEMPLATE, TABLE_DATA_DISCHARGE_TEMPLATE, TABLE_HEADERS_DISCHARGE } from "../constants/discharge.constant";

export const dischargeService = (function () {
  "use strict";
  const mapTemplate =
      "`<h4><a href=\"#\" class=\"filterMapPopup\">${filterMapTranslation}</a> - ${thingName}</h4><hr>${fuelDischargeTranslation}: ${fuelDischargeValue}<br> ${dateTranslation}: ${dateValue}<br><a href=\"https://www.google.com/maps/search/?api=1&query=${discharge.latEnd}%2C${discharge.longEnd}\" class=\"googleLink\" target='_blank'>${goToGoogleTranslation}</a> <script> document.querySelector(\".filterMapPopup\").onclick = ()=>{console.log(filterMap);filterMap(marker.businessData.refname)};</script>`";

  async function getDischargeSummary(filters) {
    let filterValues = await configurationService.getDefaultFilterValues();
    let filterData = processFilters(filters);
    let data = null;
    if (filterData.reporFixedKey === TimeRanges.CUSTOM) {
      filterData.originalFilters = filters;
      await dischargeApi.getDischargesCustomTime(filterData);
      return null;
    } else {
      data = await dischargeApi.getDischargeFixedRange(filterData);
    }
    await filterVarillaData(data, filterValues);
    return processReportData(data);
  }

  function filterDischarges(discharges, filterValues, minDischarge) {
    let result = [];
    if (!minDischarge && minDischarge !== 0) {
      minDischarge = filterValues.discharge;
    }
    if (discharges && discharges.length) {
      for (const discharge of discharges) {
        if (discharge.totalDischarged > minDischarge) {
          result.push(discharge);
        }
      }
    }
    return result;
  }

  const getVarillaDataOf = async (data) => {
    const arrThings = [];
    data.forEach(thing => arrThings.push(thing.idThing));
    const res = await thingApi.getThingsByIdWithProjection(arrThings, {varilla: 1});
    const map = new Map();
    res.forEach(item => {
      map.set(item._id, item.varilla);
    })
    return map;
  }

  async function generateReportByData(data) {
    let filterValues = await configurationService.getDefaultFilterValues();
    const result = {};
    await filterVarillaData(data, filterValues);
    const { chart, maps, table, initPosition } = await processReportData(data);
    result.table = table;
    result.maps = maps;
    result.chart = chart;
    result.initPosition = initPosition;
    return result;
  }

  async function filterVarillaData(data, filterValues) {
    const varillaData = await getVarillaDataOf(data);
    data.forEach(thing => {
      const thingDischarges = thing.discharges.filter((disch) => disch.totalDischarged > 0);
      thing.discharges.cleanAndUpdate(thingDischarges);
      thing.discharges = filterDischarges(thing.discharges, filterValues, varillaData.get(thing.idThing).discharge);
    });
  }

  async function getDischargeDashboard(filters) {
    let filterValues = await configurationService.getDefaultFilterValues();
    const filterData = processFilters(filters);
    const data = await dischargeApi.getDischargeFixedRange(filterData);

    await filterVarillaData(data, filterValues);
    return generateChart(data);
  }

  function initChart(data) {
    // CHART init
    let chart = deepcopy(DISCHARGE_CHAT_JSON_TEMPLATE);
    chart.options.tooltips.callbacks.label = (data) => {
      return `${i18n.t('dischargeSummary.chart.dataset.label')}: ${data.value} ${unitParser.getUnit(store.getters['user/getInternationalization'].unit, unitParser.UNITS_NAMES.VOLUME)}`
    }
    chart.id = new Date().getTime();
    chart.name = i18n.t(chart.name);
    const maxdischarges = getMaxCountDischarges(data);
    chart.data.datasets = getInitDataset(maxdischarges);
    return { chart, maxdischarges };
  }

  function generateChart(data) {
    const { chart, maxdischarges } = initChart(data);
    if (data && data.length > 0) {
      for (const thing of data) {
        processChartThing(chart, thing, maxdischarges);
      }
    }
    return [chart];
  }

  function processChartThing(chart, thing, maxdischarges) {
    chart.data.labels.push(thing.thingName);
    for (let i = 0; i < maxdischarges; i++) {
      let total = (x, y = true) => { return x ? unitParser.parseVolumen(x.totalDischarged, store.getters['user/getInternationalization'].unit, y) : unitParser.parseVolumen(0, store.getters['user/getInternationalization'].unit, y) }

      chart.data.datasets[i].data.push(total(thing.discharges[i], false));

      chart.data.datasets[i].formattedTooltipData.label.push(total(thing.discharges[i]));
    }
  }
  async function processReportData(data) {

    // CHART init
    const { chart, maxdischarges } = initChart(data);

    //MAP init
    const map = deepcopy(DISCHARGE_MAP_JSON_TEMPLATE);

    //TABLE init
    const tableHeaders = deepcopy(TABLE_HEADERS_DISCHARGE);
    for (const header of tableHeaders) {
      header["text"] = unitParser.changeUnit(i18n.t(header["text"]), unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, function (text) { return i18n.t(text) });
    }
    const tableDataFinal = [];

    //init position
    let initPosition = {
      lat: null,
      long: null,
      zoom: 2.5
    }

    const mapConfig = await configurationService.get("dischargeSummary.map");
    if (mapConfig) {
      initPosition.currentLayer = mapConfig.data.currentLayer;
    }

    if (data && data.length > 0) {
      data.forEach((thing, index) => {
        if (thing.totalDischargedSort <= 0) {
          return;
        }
        //CHART
        processChartThing(chart, thing, maxdischarges);

        //MAP
        const reference = deepcopy(DISCHARGE_MAP_REFERENCE_TEMPLATE);
        reference.name = thing.thingName;
        reference.title = thing.thingName;
        map.groups[0].references.push(reference);
        let dischargeId = thing.thingId + index;
        let totalDischarged = 0;
        thing.discharges.forEach((discharge, counter) => {
          totalDischarged += discharge.totalDischarged;
          /* eslint-disable no-unused-vars */
          const thingName = thing.thingName;
          const dateTranslation = i18n.t("dischargeSummary.map.popup.date");
          const dateValue = parseTimestamp(discharge.trackTimestampEnd);
          const fuelDischargeTranslation = unitParser.changeUnit(i18n.t("dischargeSummary.map.popup.fuelDischarge"), unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, function (text) { return i18n.t(text) });
          const filterMapTranslation = i18n.t("dischargeSummary.map.popup.filterMap");
          const goToGoogleTranslation = i18n.t("dischargeSummary.map.popup.goToGoogleTranslation");
          const fuelDischargeValue = unitParser.parseVolumen(discharge.totalDischarged, store.getters['user/getInternationalization'].unit, true);
          /* eslint-enable no-unused-vars */
          const dischargePoint = deepcopy(DISCHARGE_MAP_POINT_JSON_TEMPALTE);
          dischargePoint.id = dischargeId + counter;
          dischargePoint.lat = discharge.latEnd;
          dischargePoint.lng = discharge.longEnd;
          dischargePoint.value = thingName;
          dischargePoint.marker.businessData = { hasLinks: true };
          dischargePoint.marker.businessData.popup = eval(mapTemplate);
          map.groups[0].series[0].points.push(dischargePoint);
          dischargePoint.marker.businessData.number = index + 1;
          dischargePoint.marker.businessData.refname = reference.name;
          initPosition.lat = discharge.latEnd;
          initPosition.long = discharge.longEnd;
        })

        //TABLE
        const tableData = deepcopy(TABLE_DATA_DISCHARGE_TEMPLATE);
        tableData.nameThing = thing.thingName;
        tableData.serviceType = thing.serviceTypeKey && thing.serviceTypeKey !== '' ? i18n.t("table.data.serviceType." + thing.serviceTypeKey) : '';
        tableData.nameDriver = thing.currentDriverName;
        tableData.positionTimestamp = parseTimestamp(thing.lastTrackTimestamp);
        tableData.communicationTimestamp = parseTimestamp(thing.lastComunicationTimestamp);
        tableData.dischargesAmount = thing.discharges.length.toString();
        tableData.totalLiters = unitParser.parseVolumen(totalDischarged, store.getters['user/getInternationalization'].unit, true);
        tableData.positionTimestampColor = ReportConversionUtil.getTimestampColor(thing.lastTrackTimestamp);
        tableData.communicationTimestampColor = ReportConversionUtil.getTimestampColor(thing.lastComunicationTimestamp);
        tableData.number = index + 1;
        tableData.thingId = thing.idThing;
        tableData.thingName = thing.thingName;
        tableData.geoReferenceEnd = thing.geoReferenceEnd;

        // cargas para el detalle
        tableData.discharges = thing.discharges;

        tableDataFinal.push(tableData);
      })
    }
    if (!initPosition.lat) {
      initPosition = null;
    }
    return {
      chart: [chart],
      maps: [map],
      table: {
        headers: tableHeaders,
        data: tableDataFinal
      },
      initPosition
    }
  }

  function getMaxCountDischarges(summaries) {
    let result = 0;
    if (summaries && summaries.length > 0) {
      for (const thingSummary of summaries) {
        const length = thingSummary.discharges && thingSummary.discharges.length || 0;
        if (length > result) {
          result = length;
        }
      }
    }
    return result;
  }

  function getInitDataset(maxDischarges) {
    const result = [];
    for (let i = 0; i < maxDischarges; i++) {
      const dischargeDataset = deepcopy(DISCHARGE_CHART_DATASET_TEMPLATE);
      dischargeDataset.label = i18n.t('dischargeSummary.chart.dataset.label');
      dischargeDataset.backgroundColor = (i % 2 !== 0) ? reportsColors.DISCHARGES_EVEN : reportsColors.DISCHARGES_ODD;
      result.push(dischargeDataset);
    }
    return result;
  }

  return {
    getDischargeSummary,
    getDischargeDashboard,
    generateReportByData
  };
})();
