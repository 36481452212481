import { configurationService } from "@/business/configurationService";
import { filterService } from "@/business/filterService";
import { performanceService } from "@/business/performanceService";
import FilterComponent from "@/components/filter/FilterComponent.vue";
import { REPORT_TYPES, SnackbarStyle } from "@/constants/constants";
import i18n from "@/i18n";
import { mapActions } from "vuex";
import { breadcrumbService } from "@/business/breadcrumb.service";

export default {
  components: {
    FilterComponent,
  },
  props: {
      internationalization: {
          required: false
        }
  },
  data: () => ({
    title: i18n.t("performanceDashboard.chart.title"),
    model: {
      charts: [],
      filters: filterService.getFilterModel(),
    },
    showFilters: false,
    loading: false,
    reportDetails: "Deatils",
    orderFilterWorstBestPropertyName:null,
    dataForRangeFilter:null,
    maxQuantityLimit: 10,
    chartCustomButtons: []
  }),
  async mounted() {
    this.loading = true;    
    this.orderFilterWorstBestPropertyName = this.internationalization(i18n.t("performanceSummary.filterProperties.worstBestProperty"));
    this.dataForRangeFilter= {
      showRangeData: false,
      minLimit: 0,
      maxLimit: 1000,
      propertyName: this.internationalization(i18n.t("performanceSummary.filterProperties.rangeProperty")),
    };
    try {
      const configuration = await configurationService.get(
        REPORT_TYPES.DASHBOARD_PERFORMANCE
      );
      this.model.filters =
        configuration && configuration.data && configuration.data.filters
          ? configuration.data.filters
          : filterService.getFilterModel();
      let charts = await performanceService.getPerformanceDashboard(this.model.filters);
      this.model.charts.cleanAndUpdate(charts);
      this.chartCustomButtons.push({ id: 'showSummaryPerformance', name: 'showReport', icon: 'mdi-file-link', function: () => { this.onClickChart(); } });
      this.reportDetails = breadcrumbService.getFilterDetailsHTMLExport(this.model.filters, "PERFORMANCE");
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions({
      showSnackbar: "snackbar/showSnackbar",
      closeSnackbar: "snackbar/closeSnackbar",
    }),
    openFilter() {
      this.showFilters = true;
    },
    closeFilter() {
      this.showFilters = false;
    },
    onClickChart() {
      this.$router.push({ name: "performanceSummary", params: this.model.filters });
    },
    async saveFilterChanges() {
      configurationService
        .save(REPORT_TYPES.DASHBOARD_PERFORMANCE, { filters: this.model.filters })
        .then(() => {
          this.showSnackbar({
            visible: true,
            text: i18n.t("user.configuration.saveSuccess"),
            timeout: 10000,
            style: SnackbarStyle.SUCCESS,
          });
        })
        .catch(() => {
          this.showSnackbar({
            visible: true,
            text: i18n.t("user.configuration.saveError"),
            timeout: 10000,
            style: SnackbarStyle.ERROR,
          });
        });
      let charts = await performanceService.getPerformanceDashboard(this.model.filters);
      this.model.charts.cleanAndUpdate(charts);
      this.reportDetails = breadcrumbService.getFilterDetailsHTMLExport(this.model.filters, "PERFORMANCE");
      this.closeFilter();
    },
  },
};
