import { CellType } from '@colven/common-domain-lib/lib';
import i18n from "@/i18n";

export const CHARGE_MAP_POINT_JSON_TEMPALTE = {
  id: null,
  lat: null,
  lng: null,
  value: "TRUCK",
  filters: [],
  marker: {
    title: "",
    type: "img",
    ref: "truck-icon.svg",
    size: 40,
    tooltipDirection: "right",
    tooltipPermanent: false,
    tooltipBorderColor: "rgb(0,176,240)",
    businessData: {
      number: 0,
      direction: "northeast",
      stopped: false,
    },
  },
};

export const CHARGE_MAP_JSON_TEMPLATE = {
  name: i18n.t("chargeSummary.map.name"),
  groups: [
    {
      name: i18n.t("chargeSummary.map.groupName"),
      title: i18n.t("chargeSummary.map.groupTitle"),
      measure: undefined,
      referenceByInterval: false,
      series: [
        {
          type: "MARKERS",
          points: [],
        },
      ],
      references: [],
      exclusive: true,
      displayOnStart: true,
    },
  ],
  filters: [],
  info: {},
};

export const TABLE_HEADERS_CHARGE = [
  {
    text: "table.headers.charge.nameThing",
    align: "left",
    filterType: "TEXT_FIELD",
    selected: true,
    sortable: true,
    type: "STRING",
    value: "nameThing",
    width: 220,
  },
  {
    text: "table.headers.charge.serviceType",
    align: "left",
    filterType: "TEXT_FIELD",
    selected: true,
    sortable: true,
    type: "STRING",
    value: "serviceType",
    width: 220,
  },
  {
    text: "table.headers.charge.nameDriver",
    align: "left",
    filterType: "TEXT_FIELD",
    selected: true,
    sortable: true,
    type: "STRING",
    value: "nameDriver",
    width: 220,
  },
  {
    text: "table.headers.charge.positionTimestamp",
    align: "left",
    filterType: "TEXT_FIELD",
    selected: true,
    sortable: true,
    type: "STRING",
    cellType: CellType.textBackgroundColor,
    cellConfig: {
      round: false,
      outlined: true,
      color: "positionTimestampColor",
    },
    value: "positionTimestamp",
    width: 220,
  },
  {
    text: "table.headers.charge.communicationTimestamp",
    align: "left",
    filterType: "TEXT_FIELD",
    selected: true,
    sortable: true,
    type: "STRING",
    cellType: CellType.textBackgroundColor,
    cellConfig: {
      round: false,
      outlined: true,
      color: "communicationTimestampColor",
    },
    value: "communicationTimestamp",
    width: 220,
  },
  {
    text: "table.headers.charge.chargesAmount",
    align: "left",
    filterType: "TEXT_FIELD",
    selected: true,
    sortable: true,
    type: "STRING",
    value: "chargesAmount",
    width: 220,
  },
  {
    text: "table.headers.charge.totalLiters",
    align: "left",
    filterType: "TEXT_FIELD",
    selected: true,
    sortable: true,
    type: "STRING",
    value: "totalLiters",
    width: 220,
  },
];

export const TABLE_DATA_CHARGE_TEMPLATE = {
  thingId: null,
  thingName: null,
  serviceTypeKey: null,
  serviceType: null,
  nameDriver: null,
  positionTimestamp: null,
  communicationTimestamp: null,
  chargesAmount: null,
  totalLiters: null,
  positionTimestampColor: null,
  communicationTimestampColor: null,
};

export const CHARGE_MAP_REFERENCE_TEMPLATE = {
  name: null,
  color: "rgb(0,176,240)",
  isInterval: false,
  title: null,
};

export const CHARGE_CHAT_JSON_TEMPLATE = {
  type: "bar",
  name: "charges",
  data: {
    labels: [],
    datasets: [],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    legend: false,
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    tooltips: {},
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: "xy",
        },
        zoom: {
          enabled: true,
          mode: "xy",
        },
      },
    },
  },
};

export const CHARGE_CHART_DATASET_TEMPLATE = {
  label: "chargeSummary.chart.dataset.label",
  data: [],
  backgroundColor: "#D6E9C6",
    type: undefined,
    dataProperty: undefined,
    originalData: [],
    formattedTooltipData: {
        label: []
    },
    formattedLabelData: [],
    fill: true,
    yAxisID: undefined,
    lineTension: 0
};
