import http from "@/middleware";

export const chargeApi = {

  async getChargeFixedRange(filters) {
    const result = await http.post("/api/varilla-report/charge-fixed-range", filters);
    return result.data;
  },

  async getChargesCustomTime(filters) {
    const lang = localStorage.getItem("locale");
    filters.lang = lang;
    const result = await http.post("/api/varilla-report/chargeCustomTime", filters);
    return result.data;
  }

};

export default chargeApi;
