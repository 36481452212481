import http from "@/middleware";

export const dischargeApi = {
  async getDischargeFixedRange(filters) {
    const result = await http.post("/api/varilla-report/dischargeFixedRange", filters); 
    return result.data;
  },
  async getDischargesCustomTime(filters) {
    const lang = localStorage.getItem("locale");
    filters.lang = lang;
    const result = await http.post("/api/varilla-report/dischargeCustomTime", filters);
    return result.data;
  }
};

export default dischargeApi;
