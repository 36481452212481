import chargeApi from "@/api/chargeApi";
import thingApi from "@/api/thing.api.js";
import { CHARGE_CHART_DATASET_TEMPLATE, CHARGE_CHAT_JSON_TEMPLATE, CHARGE_MAP_JSON_TEMPLATE, CHARGE_MAP_POINT_JSON_TEMPALTE, CHARGE_MAP_REFERENCE_TEMPLATE, TABLE_DATA_CHARGE_TEMPLATE, TABLE_HEADERS_CHARGE } from "@/constants/charge.constant";
import i18n from "@/i18n";
import { processFilters, parseTimestamp } from "@/tools/functions";
import { ReportConversionUtil, TimeRanges} from '@colven/common-domain-lib/lib';
import * as deepcopy from "deepcopy";
import { configurationService } from "@/business/configurationService";
import { reportsColors } from "@/constants/constants";
import { unitParser } from '@colven/common-domain-lib/lib';
import store from "@/store/store";

export const chargeService = (function () {
    "use strict";
    const mapTemplate =
        '`<h4><a href="#" class="filterMapPopup">${filterMapTranslation}</a> - ${thingName}</h4><hr>${fuelChargeTranslation}: ${fuelChargeValue}<br> ${dateTranslation}: ${dateValue}<br><a href="https://www.google.com/maps/search/?api=1&query=${charge.latEnd}%2C${charge.longEnd}" class="googleLink" target="_blank">${goToGoogleTranslation}</a> <script> document.querySelector(".filterMapPopup").onclick = ()=>{console.log(filterMap);filterMap(marker.businessData.refname)};</script>`';


    async function getChargeSummary(filters) {
        const filterData = processFilters(filters);
        let data = null;
        if (filterData.reporFixedKey === TimeRanges.CUSTOM) {
            filterData.originalFilters = filters;
            await chargeApi.getChargesCustomTime(filterData);
            return null;
        } else {
            data = await chargeApi.getChargeFixedRange(filterData);
        }
        return generateReportByData(data, filterData.reportBy)
    }

    async function generateReportByData(data) {
        const result = {};
        let filterValues = await configurationService.getDefaultFilterValues();
        await filterVarillaData(data, filterValues);
        const { chart, map, table, initPosition } = await processReportData(data);
        result.table = table;
        result.maps = map;
        result.chart = chart;
        result.initPosition = initPosition;
        return result;
    }

    async function filterVarillaData(data, filterValues) {
        const varillaData = await getVarillaDataOf(data);
        //filtro de cargas menores o iguales a cero
        data.forEach(thing => {
            const thingCharges = thing.charges.filter((charge) => charge.totalCharged > 0);
            thing.charges.cleanAndUpdate(thingCharges);
            thing.charges = filterCharges(thing.charges, filterValues, varillaData.get(thing.idThing).charge);
        });
    }

    async function getChargeDashboard(filters) {
        const filterValues = await configurationService.getDefaultFilterValues();
        const filterData = processFilters(filters);
        const data = await chargeApi.getChargeFixedRange(filterData);
        await filterVarillaData(data, filterValues);
        return generateChart(data);
    }

    function filterCharges(charges, filterValues, minCharge) {
        let result = [];
        if (!minCharge && minCharge !== 0) {
            minCharge = filterValues.charge;
        }
        if (charges && charges.length) {
            for (const charge of charges) {
                if (charge.totalCharged > minCharge) {
                    result.push(charge);
                }
            }
        }
        return result;
    }
    const getVarillaDataOf = async (data) => {
        const arrThings = [];
        data.forEach(thing => arrThings.push(thing.idThing));
        const res = await thingApi.getThingsByIdWithProjection(arrThings, { varilla: 1 });
        const map = new Map();
        res.forEach(item => {
            map.set(item._id, item.varilla);
        })
        return map;
    }

    function initChart(data) {
        // CHART init
        const chart = deepcopy(CHARGE_CHAT_JSON_TEMPLATE);
        chart.id = new Date().getTime();
        chart.name = i18n.t(chart.name);
        const maxCharges = getMaxCountCharges(data);
        chart.data.datasets = getInitDataset(maxCharges);
        return { chart, maxCharges };
    }

    function generateChart(data) {
        const { chart, maxCharges } = initChart(data);
        if (data && data.length > 0) {
            for (const thing of data) {
                processChartThing(chart, thing, maxCharges);
            }
        }
        return [chart];
    }

    function processChartThing(chart, thing, maxCharges) {
        chart.data.labels.push(thing.thingName);
        for (let i = 0; i < maxCharges; i++) {
            let total = (x, y = true) => { return x ? unitParser.parseVolumen(x.totalCharged, store.getters['user/getInternationalization'].unit, y) : unitParser.parseVolumen(0, store.getters['user/getInternationalization'].unit, y) }
            chart.data.datasets[i].data.push(total(thing.charges[i], false));
            chart.data.datasets[i].formattedTooltipData.label.push(total(thing.charges[i], true));
        }
    }

    async function processReportData(data) {
        // CHART init
        const { chart, maxCharges } = initChart(data);

        //MAP init
        const map = deepcopy(CHARGE_MAP_JSON_TEMPLATE);

        //TABLE init
        const tableHeaders = deepcopy(TABLE_HEADERS_CHARGE);
        for (const header of tableHeaders) {
            header["text"] = unitParser.changeUnit(i18n.t(header["text"]), unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, function (text) { return i18n.t(text) });
        }
        const tableDataFinal = [];

        //init position
        let initPosition = {
            lat: null,
            long: null,
            zoom: 2.5
        }

        const mapConfig = await configurationService.get("chargeSummary.map");
        if (mapConfig) {
            initPosition.currentLayer = mapConfig.data.currentLayer;
        }

        if (data && data.length > 0) {
            data.forEach((thing, index) => {
                if (thing.totalChargedSort <= 0) {
                    return;
                }
                //CHART
                processChartThing(chart, thing, maxCharges);

                //MAP
                const reference = deepcopy(CHARGE_MAP_REFERENCE_TEMPLATE);
                reference.name = thing.thingName;
                reference.title = thing.thingName;
                map.groups[0].references.push(reference);
                let chargeId = thing.idThing;
                let totalCharged = 0;
                thing.charges.forEach((charge, counter) => {
                    totalCharged += charge.totalCharged;
                    /* eslint-disable no-unused-vars */
                    const thingName = thing.thingName;
                    const dateTranslation = i18n.t("chargeSummary.map.popup.date");
                    const dateValue = parseTimestamp(charge.trackTimestampEnd);
                    const fuelChargeTranslation = unitParser.changeUnit(i18n.t("chargeSummary.map.popup.fuelCharge"), unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, function (text) { return i18n.t(text) });
                    const filterMapTranslation = i18n.t("chargeSummary.map.popup.filterMap");
                    const goToGoogleTranslation = i18n.t("chargeSummary.map.popup.goToGoogleTranslation");
                    const fuelChargeValue = unitParser.parseVolumen(charge.totalCharged, store.getters['user/getInternationalization'].unit, true);
                    /* eslint-enable no-unused-vars */
                    const chargePoint = deepcopy(CHARGE_MAP_POINT_JSON_TEMPALTE);
                    chargePoint.id = chargeId + counter;
                    chargePoint.lat = charge.latEnd;
                    chargePoint.lng = charge.longEnd;
                    chargePoint.value = thingName;
                    chargePoint.marker.businessData = { hasLinks: true };
                    chargePoint.marker.businessData.popup = eval(mapTemplate);
                    map.groups[0].series[0].points.push(chargePoint);
                    chargePoint.marker.businessData.number = index + 1;
                    chargePoint.marker.businessData.refname = reference.name;
                    initPosition.lat = charge.latEnd;
                    initPosition.long = charge.longEnd;
                })

                //TABLE
                const tableData = deepcopy(TABLE_DATA_CHARGE_TEMPLATE);
                tableData.nameThing = thing.thingName;
                tableData.serviceType = thing.serviceTypeKey && thing.serviceTypeKey !== '' ? i18n.t("table.data.serviceType." + thing.serviceTypeKey) : '';
                tableData.nameDriver = thing.currentDriverName;
                tableData.positionTimestamp = parseTimestamp(thing.lastTrackTimestamp)
                tableData.communicationTimestamp =  parseTimestamp(thing.lastComunicationTimestamp)
                tableData.chargesAmount = thing.charges.length.toString();
                //tableData.totalLiters = thing.charges.reduce((sum, charge) => sum + charge["totalCharged"], 0).toString();
                tableData.totalLiters = unitParser.parseVolumen(totalCharged, store.getters['user/getInternationalization'].unit, true);
                tableData.positionTimestampColor = ReportConversionUtil.getTimestampColor(thing.lastTrackTimestamp);
                tableData.communicationTimestampColor = ReportConversionUtil.getTimestampColor(thing.lastComunicationTimestamp);
                tableData.number = index + 1;
                tableData.thingId = thing.idThing;
                tableData.thingName = thing.thingName;
                // cargas para el detalle
                tableData.charges = thing.charges;

                tableDataFinal.push(tableData);
            })
        }
        if (!initPosition.lat) {
            initPosition = null;
        }
        return {
            chart: [chart],
            map: [map],
            table: {
                headers: tableHeaders,
                data: tableDataFinal
            },
            initPosition
        }
    }

    function getMaxCountCharges(summaries) {
        let result = 0;
        if (summaries && summaries.length > 0) {
            for (const thingSummary of summaries) {
                const length = thingSummary.charges && thingSummary.charges.length || 0;
                if (length > result) {
                    result = length;
                }
            }
        }
        return result;
    }

    function getInitDataset(maxCharges) {
        const result = [];
        for (let i = 0; i < maxCharges; i++) {
            const chargeDataset = deepcopy(CHARGE_CHART_DATASET_TEMPLATE);
            chargeDataset.backgroundColor = (i % 2 !== 0) ? reportsColors.CHARGES_EVEN : reportsColors.CHARGES_ODD;
            chargeDataset.label = i18n.t('chargeSummary.chart.dataset.label');
            result.push(chargeDataset);
        }
        return result;
    }

    return {
        getChargeSummary,
        getChargeDashboard,
        generateReportByData
    };
})();